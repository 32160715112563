/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

.client-status-modal {

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    display: none;
    background: rgba(0, 0, 0, 0.5);

}

.client-status-modal.shown {
    display: block;
}

.client-status-modal guac-modal {
    position: absolute;
}

.client-status-modal .notification {
    background: rgba(40, 40, 40, 0.75);
    color: white;
    width: 100%;
    max-width: 100%;
    padding: 1em;
    text-align: center;
    border: none;
}

.client-status-modal .notification.error {
    background: rgba(112, 9, 8, 0.75)
}

.client-status-modal .notification .title-bar {
    display: none
}

.client-status-modal .notification .button {
    background: transparent;
    border: 2px solid white;
    box-shadow: none;
    text-shadow: none;
    font-weight: normal;
}

.client-status-modal .notification .button:hover {
    text-decoration: underline;
    background: rgba(255, 255, 255, 0.25);
}

.client-status-modal .notification .button:active {
    background: rgba(255, 255, 255, 0.5);
}

.client-status-modal .notification .parameters {
    width: 100%;
    max-width: 5in;
    margin: 0 auto;
}

.client-status-modal .notification .parameters h3,
.client-status-modal .notification .parameters .password-field .toggle-password {
    display: none;
}

.client-status-modal .notification .parameters input[type=email],
.client-status-modal .notification .parameters input[type=number],
.client-status-modal .notification .parameters input[type=password],
.client-status-modal .notification .parameters input[type=text],
.client-status-modal .notification .parameters textarea {
    background: transparent;
    border: 2px solid white;
    color: white; 
}

/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

.client-zoom .client-zoom-out,
.client-zoom .client-zoom-in,
.client-zoom .client-zoom-state {
    display: inline-block;
    vertical-align: middle;
}

.client-zoom .client-zoom-out,
.client-zoom .client-zoom-in {
    max-width: 3em;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 2em;
    margin: 0.5em;
    cursor: pointer;
}

.client-zoom .client-zoom-out img,
.client-zoom .client-zoom-in img {
    width: 100%;
    opacity: 0.5;
}

.client-zoom .client-zoom-out:hover,
.client-zoom .client-zoom-in:hover {
    border: 1px solid rgba(0, 0, 0, 1);
    background: #CDA;
}

.client-zoom .client-zoom-out:hover img,
.client-zoom .client-zoom-in:hover img {
    opacity: 1;
}

.client-zoom .client-zoom-state {
    font-size: 1.5em;
}

.client-zoom .client-zoom-autofit {
    text-align: left;
    margin-top: 1em;
}

.client-zoom .client-zoom-state input {
    width: 2em;
    font-size: 1em;
    padding: 0;
    background: transparent;
    border-color: rgba(0, 0, 0, 0.125);
}

.client-zoom .client-zoom-state input::-webkit-inner-spin-button,
.client-zoom .client-zoom-state input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

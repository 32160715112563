/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

/* Hide directory contents by default */

.file-browser .directory > .children {
    padding-left: 1em;
    display: none;
}

.file-browser .list-item .caption {
    white-space: nowrap;
    border: 1px solid transparent;
}

.file-browser .list-item.focused .caption {
    border: 1px dotted rgba(0, 0, 0, 0.5);
    background: rgba(204, 221, 170, 0.5);
}

/* Directory / file icons */

.file-browser .normal-file > .caption .icon {
    background-image: url('images/file.svg');
}

.file-browser .directory > .caption .icon {
    background-image: url('images/folder-closed.svg');
}

.file-browser .directory.previous > .caption .icon {
    background-image: url('images/folder-up.svg');
}

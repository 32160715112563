/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

/* Keep toggle-password icon on same line */
.form-field .password-field {
    white-space: nowrap;
}

/* Generic 1x1em icon/button */
.form-field .password-field .icon.toggle-password {

    display: inline-block;
    opacity: 0.5;
    cursor: default;

    background-repeat: no-repeat;
    background-size: 1em;
    width: 1em;
    height: 1em;

}

/* Icon for unmasking passwords */
.form-field .password-field input[type=password] ~ .icon.toggle-password {
    background-image: url('images/action-icons/guac-show-pass.svg');
}

/* Icon for masking passwords */
.form-field .password-field input[type=text] ~ .icon.toggle-password {
    background-image: url('images/action-icons/guac-hide-pass.svg');
}

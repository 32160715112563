/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

.loading {
    position: relative;
    min-height: 200px;
}

.view.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.loading * {
    visibility: hidden;
}

.loading::before {

    display: block;
    position: absolute;
    content: '';
    
    /* Dictated by size of image */
    width: 96px;
    height: 96px;
    margin-left: -48px;
    margin-top: -48px;
    
    top: 50%;
    left: 50%;
    
    background-image: url('images/cog.svg');
    background-size: 96px 96px;
    background-position: center center;
    background-repeat: no-repeat;
    
    animation:         spinning-cog 4s linear infinite;
    -moz-animation:    spinning-cog 4s linear infinite;
    -webkit-animation: spinning-cog 4s linear infinite;
    
}

@keyframes spinning-cog {
    0%   { transform: rotate(0deg);   }
    100% { transform: rotate(360deg); }
}

@-moz-keyframes spinning-cog {
    0%   { -moz-transform: rotate(0deg);   }
    100% { -moz-transform: rotate(360deg); }
}

@-webkit-keyframes spinning-cog {
    0%   { -webkit-transform: rotate(0deg);   }
    100% { -webkit-transform: rotate(360deg); }
}

/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

#guac-menu .header h2.connection-select-menu {
    overflow: visible;
}

.connection-select-menu {
    padding: 0;
    min-width: 0;
}

.connection-select-menu .menu-dropdown {
    border: none;
}

.connection-select-menu .menu-dropdown .menu-contents {
    font-weight: normal;
    font-size: 0.8em;
    right: auto;
    left: 0;
    max-width: 100vw;
    width: 400px;
}

.connection-select-menu .menu-dropdown .menu-contents .filter input {
    border-bottom: 1px solid rgba(0,0,0,0.125);
    border-left: none;
}

.connection-select-menu .menu-dropdown .menu-contents .filter {
    margin-bottom: 0.5em;
    padding: 0;
}

.connection-select-menu .menu-dropdown .menu-contents .group-list .caption {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.connection-select-menu .menu-dropdown .menu-contents .caption .connection,
.connection-select-menu .menu-dropdown .menu-contents .caption .connection-group {
    display: inline-block;
}

/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

div.login-ui {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: table;
    background: white;
    z-index: 20;
}

.login-ui p.login-error {
    display: none;
}

.login-ui.error p.login-error {
    display: block;

    position: fixed;
    left: 0;
    right: 0;
    top: 0;

    padding: 1em;
    margin: 0.2em;

    background: #FDD;
    border: 1px solid #964040;
    -moz-border-radius: 0.25em;
    -webkit-border-radius: 0.25em;
    -khtml-border-radius: 0.25em;
    text-align: center;
    color: #964040;
}

.login-ui .login-fields .form-field .password-field .toggle-password {
    display: none;
}

.login-ui .login-fields .labeled-field {
    display: block;
    position: relative;
    z-index: 1;
}

.login-ui .login-fields .labeled-field .field-header {

  display: block;
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;

  z-index: -1;
  margin: 0.5em;
  font-size: 0.9em;
  opacity: 0.5;

}

.login-ui .login-fields .labeled-field.empty input {
    background: transparent;
}

.login-ui .login-fields .labeled-field input:focus {
    background: white;
}

/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

#filesystem-menu .header h2 {
    font-size: 1em;
    font-weight: normal;
    padding-top: 0;
    padding-bottom: 0;
}

#filesystem-menu .header {
    -ms-flex-align:      center;
    -moz-box-align:      center;
    -webkit-box-align:   center;
    -webkit-align-items: center;
    align-items:         center;
}

#filesystem-menu .menu-body {
    padding: 0.25em;
}

#filesystem-menu .header.breadcrumbs {
    display: block;
    background: rgba(0,0,0,0.0125);
    border-bottom: 1px solid rgba(0,0,0,0.05);
    box-shadow: none;
    margin-top: 0;
    border-top: none;
}

#filesystem-menu .header.breadcrumbs .breadcrumb {
    display: inline-block;
    padding: 0.5em;
    font-size: 0.8em;
    font-weight: bold;
}

#filesystem-menu .header.breadcrumbs .breadcrumb:hover {
    background-color: #CDA;
    cursor: pointer;
}

#filesystem-menu .header.breadcrumbs .breadcrumb.root {
    background-size:         1.5em 1.5em;
    -moz-background-size:    1.5em 1.5em;
    -webkit-background-size: 1.5em 1.5em;
    -khtml-background-size:  1.5em 1.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('images/drive.svg');
    width: 2em;
    height: 2em;
    padding: 0;
    vertical-align: middle;
}
/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

input[type=checkbox], input[type=number], input[type=text], input[type=email], input[type=radio], label, textarea {
    -webkit-tap-highlight-color: rgba(128,192,128,0.5);
}

div.location, input[type=text], input[type=email], input[type=number], input[type=password], textarea {
    border: 1px solid #777;
    -moz-border-radius: 0.2em;
    -webkit-border-radius: 0.2em;
    -khtml-border-radius: 0.2em;
    border-radius: 0.2em;
    width: 100%;
    max-width: 16em;
    padding: 0.25em;
    font-size: 0.8em;
    background: white;
    cursor: text;
}

textarea {
    max-width: none;
    width: 30em;
    height: 10em;
    white-space: pre;
    word-wrap: normal;
    overflow: auto;
}

/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

.page-tabs .page-list ul,
.section-tabs ul {
    margin: 0;
    padding: 0;
    background: rgba(0, 0, 0, 0.0125);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.page-tabs .page-list ul + ul,
.section-tabs ul + ul {
    font-size: 0.75em;
}

.page-tabs .page-list li,
.section-tabs li {
    display: inline-block;
    list-style: none;
}

.page-tabs .page-list li a[href],
.section-tabs li a {
    display: block;
    color: black;
    text-decoration: none;
    padding: 0.75em 1em;
}

.page-tabs .page-list li a[href]:visited {
    color: black;
}

.page-tabs .page-list li a[href]:hover,
.section-tabs li a:hover {
    background-color: #CDA;
    cursor: pointer;
}

.page-tabs .page-list li a[href].current,
.page-tabs .page-list li a[href].current:hover,
.section-tabs li a.current,
.section-tabs li a.current:hover {
    background: rgba(0,0,0,0.3);
    cursor: default;
}

/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

.user,
.user-group,
.connection-group,
.connection {
    cursor: pointer;
}

.user a,
.user-group a,
.connection a,
.connection-group a {
    text-decoration:none;
    color: black;
}

.user a:hover,
.user-group a:hover,
.connection a:hover,
.connection-group a:hover {
    text-decoration:none;
    color: black;
}

.user a:visited,
.user-group a:visited,
.connection a:visited,
.connection-group a:visited {
    text-decoration:none;
    color: black;
}

.recent-connections .connection:hover {
    background: #CDA;
}

.recent-connections .connection .thumbnail {
    display: block;
    margin: 0.5em;
}

.recent-connections .connection .thumbnail > * {
    border: 1px solid black;
    background: black;
    box-shadow: 1px 1px 5px black;
    max-width: 75%;
    display: inline-block;
}

.caption * {
    vertical-align: middle;
}

.caption .choice {
    display: inline-block;
}

.caption .name {
    margin-left: 0.25em;
}

.placeholder {

    color: rgba(255, 255, 255, 0.5);
    text-shadow: -1px -1px rgba(0, 0, 0, 0.5);
    text-align: center;
    opacity: 0.5;

    font-size: 2em;
    font-weight: bolder;

}

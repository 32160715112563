/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

/* Do not stretch attributes to fit available area */
.attributes input[type=text],
.attributes input[type=email],
.attributes input[type=password],
.attributes input[type=number] {
    width: auto;
}

.attributes .form .fields {
    display: table;
    margin: 1em;
}

.attributes .form .fields .labeled-field {
    display: table-row;
}

.attributes .form .fields .field-header,
.attributes .form .fields .form-field {
    display: table-cell;
    padding: 0.125em;
    vertical-align: top;
}

.attributes .form .fields .field-header {
    padding-right: 1em;
}

.attributes .form h3 {

    font-size: 1.25em;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.5em;
    margin: 1em 0;

    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.125);
    background: rgba(0, 0, 0, 0.04);

    width: 100%;

}

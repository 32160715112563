/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

/**
 * fadein: Fade from fully transparent to fully opaque.
 */
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/**
 * fadeout: Fade from fully opaque to fully transparent.
 */
@keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}
@-moz-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}
@-webkit-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/**
 * popin: Increase in size and opacity from invisibly tiny and transparent to
 * full size and opaque.
 */
@keyframes popin {
    from { transform: scale(0); opacity: 0; }
    to   { transform: scale(1); }
}

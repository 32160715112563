/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

#connection-warning {

    position: absolute;
    right: 0.25em;
    bottom: 0.25em;
    z-index: 20;

    width: 3in;
    max-width: 100%;
    min-height: 1em;

    border-left: 2em solid #FA0;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.25);
    background: #FFE;
    padding: 0.5em 0.75em;
    font-size: .8em;

}

#connection-warning::before {

    content: ' ';
    display: block;
    position: absolute;
    left: -2em;
    top: 0;

    width: 1.25em;
    height: 100%;
    margin: 0 0.375em;

    background: url('images/warning.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

}

/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

h1 {
    
    margin: 0;
    padding: 0.5em;

    font-size: 2em;
    vertical-align: middle;
    text-align: center;

}

h2 {
    font-size: 1.25em;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.5em;
    margin: 0;
}

.header {

    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.125);
    background: rgba(0, 0, 0, 0.04);

    margin-bottom: 1em;
    margin-top: 0;
    border-top: none;
    width: 100%;

    /* IE10 */
    display: -ms-flexbox;
    -ms-flex-align: stretch;
    -ms-flex-direction: row;

    /* Ancient Mozilla */
    display: -moz-box;
    -moz-box-align: stretch;
    -moz-box-orient: horizontal;
    
    /* Ancient WebKit */
    display: -webkit-box;
    -webkit-box-align: stretch;
    -webkit-box-orient: horizontal;

    /* Old WebKit */
    display: -webkit-flex;
    -webkit-align-items: stretch;
    -webkit-flex-direction: row;

    /* W3C */
    display: flex;
    align-items: stretch;
    flex-direction: row;

}

.header.tabbed {
    margin-bottom: 0;
}

.header ~ * .header,
.header ~ .header {
    margin-top: 1em;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.header h2 {
    -ms-flex: 1 1 auto;
    -moz-box-flex: 1;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
}

.header .filter {
    margin: 0;
    padding: 0.5em;
}

.header .filter input {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -khtml-border-radius: 0;
    border-radius: 0;
    border: none;
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    background-color: transparent;
}

/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

.history-unavailable div.recent-connections {
    display: none;
}

div.recent-connections,
div.clipboardDiv,
div.settings,
div.all-connections {
    margin: 1em;
    padding: 0;
}

.all-connections .list-buttons {
    text-align: center;
    padding: 0;
}

div.recent-connections {
    text-align: center;
}

div.recent-connections div.connection {
    -moz-border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -khtml-border-radius: 0.5em;
    border-radius: 0.5em;
    display: inline-block;
    padding: 1em;
    margin: 1em;
    text-align: center;
    max-width: 75%;
    overflow: hidden;
}

a.home-connection, .empty.balancer a.home-connection-group {
    display: block;
}

/* Show only expand/collapse icon for connection groups on home screen ... */

.all-connections .connection-group > .caption .icon {
    display: none;
}

.all-connections .connection-group > .caption .icon.expand {
    display: inline-block;
}

/* ... except for empty balancing groups, which should be rendered as if they
 * are connections. */

.all-connections .connection-group.empty.balancer > .caption .icon {
    display: inline-block;
}

.all-connections .connection-group.empty.balancer > .caption .icon.expand {
    display: none;
}

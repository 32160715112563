/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

.terminal-color-scheme-field {
    max-width: 320px;
}

.terminal-color-scheme-field select {
    width: 100%;
}

.terminal-color-scheme-field .custom-color-scheme {
    background: #EEE;
    padding: 0.5em;
    border: 1px solid silver;
    border-spacing: 0;
    margin-top: -2px;
    width: 100%;
}

.terminal-color-scheme-field .custom-color-scheme-section  {
    display: -ms-flexbox;
    display: -moz-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
}

.terminal-color-scheme-field .guac-input-color {

    display: block;
    margin: 2px;
    width: 1.5em;
    height: 1.5em;
    min-width: 1.25em;
    border-radius: 0.15em;
    line-height: 1.5em;
    text-align: center;
    font-size: 0.75em;
    cursor: pointer;
    color: black;

    -ms-flex: 1;
    -moz-box-flex: 1;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;

}

.terminal-color-scheme-field .guac-input-color.read-only {
    cursor: not-allowed;
}

.terminal-color-scheme-field .guac-input-color.dark {
    color: white;
}

.terminal-color-scheme-field .palette .guac-input-color {
    font-weight: bold;
}

/* Hide palette numbers unless color scheme details are visible */
.terminal-color-scheme-field.custom-color-scheme-details-hidden .custom-color-scheme .palette .guac-input-color {
    color: transparent;
}

/*
 * Custom color scheme details header
 */

.terminal-color-scheme-field .custom-color-scheme-details-header {
    font-size: 0.8em;
    margin: 0.5em 0;
    padding: 0;
}

.terminal-color-scheme-field .custom-color-scheme-details-header::before {
    content: '▸ ';
}

.terminal-color-scheme-field.custom-color-scheme-details-visible .custom-color-scheme-details-header::before {
    content: '▾ ';
}

/*
 * Details show/hide link
 */

/* Render show/hide as a link */
.terminal-color-scheme-field .custom-color-scheme-hide-details,
.terminal-color-scheme-field .custom-color-scheme-show-details {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    margin: 0 0.25em;
    font-weight: normal;
}

.terminal-color-scheme-field .custom-color-scheme-hide-details {
    display: none;
}

.terminal-color-scheme-field.custom-color-scheme-details-visible .custom-color-scheme-hide-details {
    display: inline;
}

.terminal-color-scheme-field.custom-color-scheme-details-visible .custom-color-scheme-show-details {
    display: none;
}

/*
 * Color scheme details
 */

.terminal-color-scheme-field .custom-color-scheme-details {
    display: none;
}

.terminal-color-scheme-field.custom-color-scheme-details-visible .custom-color-scheme-details {
    display: block;
    width: 100%;
    margin: 0.5em 0;
}

/*
 * Color picker
 */

/* Increase width of color picker to allow two even rows of eight color
 * swatches */
.guac-input-color-picker[data-theme="monolith"] {
    width: 16.25em;
}

/* Remove Guacamole-specific styles inherited from the generic button rules */
.guac-input-color-picker[data-theme="monolith"] button {
    min-width: 0;
    padding: 0;
    margin: 0;
    box-shadow: none;
}

/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

.software-cursor {
    cursor: url('images/mouse/blank.gif'),url('images/mouse/blank.cur'),default;
    overflow: hidden;
    cursor: none;
}

.guac-error .software-cursor {
    cursor: default;
}

div.main {
    overflow: auto;
    width: 100%;
    height: 100%;
    position: relative;
    font-size: 0px;
}

div.displayOuter {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: table;
}

div.displayMiddle {
    width: 100%;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

div.display {
    display: inline-block;
}

div.display * {
    position: relative;
}

div.display > * {
    margin-left: auto;
    margin-right: auto;
}

/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

/*
 * The Carlito font is licensed under the SIL Open Font License, and thus is
 * freely redistributable so long as it is distributed with software.
 *
 * The SIL OFL license can be found at http://scripts.sil.org/OFL or in the
 * "LICENSE" file within the same directory as the Carlito-*.woff font files.
 */

@font-face {
    font-family: 'Carlito';
    font-weight: normal;
    font-style: normal;
    src: url('fonts/carlito/Carlito-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Carlito';
    font-weight: bold;
    font-style: normal;
    src: url('fonts/carlito/Carlito-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Carlito';
    font-weight: normal;
    font-style: italic;
    src: url('fonts/carlito/Carlito-Italic.woff') format('woff');
}

/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

.clipboard, .clipboard-service-target {
    background: white;
}

.clipboard  {
    position: relative;
    border: 1px solid #AAA;
    -moz-border-radius: 0.25em;
    -webkit-border-radius: 0.25em;
    -khtml-border-radius: 0.25em;
    border-radius: 0.25em;
    width: 100%;
    height: 2in;
    white-space: pre;
    font-size: 1em;
    overflow: auto;
    padding: 0.25em;
}

.clipboard p,
.clipboard div {
    margin: 0;
}

.clipboard img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;
    border: 1px solid black;
    background: url('images/checker.svg');
}

.clipboard-service-target {
    position: fixed;
    left: -1em;
    right: -1em;
    width: 1em;
    height: 1em;
    white-space: pre;
    overflow: hidden;
}
